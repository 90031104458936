var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('VDialog',{style:({ zIndex: 990 }),attrs:{"value":_vm.visible,"fullscreen":true,"persistent":true},on:{"input":_vm.handleClose}},[_c('Vuescroll',{attrs:{"ops":_vm.scrollOptions}},[_c('VCard',[_c('div',{staticClass:"fullscreen-modal"},[_c('div',{staticClass:"fullscreen-modal__header space-b-6"},[_c('div',{staticClass:"fullscreen-modal__grid"},[_c('div',{staticClass:"fullscreen-modal__title"},[_c('div',{staticClass:"text-h3-1"},[_vm._v("Копии задания в других курсах")])])]),_c('div',{staticClass:"fullscreen-modal__close"},[_c('VBtn',{attrs:{"color":"grey","icon":""},on:{"click":_vm.handleClose}},[_c('VIcon',[_vm._v("$close")])],1)],1)]),_c('div',{staticClass:"fullscreen-modal__grid space-b-6"},[_c('div',{staticClass:"fullscreen-modal__container filter"},[_c('TextInput',{staticClass:"filter__col-3",attrs:{"placeholder":"Курс","dense":"","outlined":"","rounded":"","label":"Курс","append-icon":"$search","clearable":true},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)]),_c('div',{staticClass:"fullscreen-modal__grid"},[_c('div',{staticClass:"fullscreen-modal__container"},[_c('VDataTable',{staticClass:"row-pointer",attrs:{"headers":_vm.headers,"items":_vm.courses,"footer-props":_vm.footerOptions,"options":_vm.filter,"hide-default-footer":""},on:{"update:options":function($event){_vm.filter=$event},"click:row":_vm.handleRowClick,"update:items-per-page":function($event){_vm.filter.page = 1}},scopedSlots:_vm._u([{key:"item.author",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.author ? ((item.author.name) + " " + (item.author.surname)) : ''))])]}},{key:"item.spendingAt",fn:function(ref){
var item = ref.item;
return [(item.spendingAt)?_c('span',{directives:[{name:"date",rawName:"v-date",value:({ date: item.spendingAt, localTZ: _vm.isLocalTimezone, mask: 'dd MMM yyyy, HH:mm' }),expression:"{ date: item.spendingAt, localTZ: isLocalTimezone, mask: 'dd MMM yyyy, HH:mm' }"}]}):_vm._e()]}},{key:"item.deadlineAt",fn:function(ref){
var item = ref.item;
return [(item.deadlineAt)?_c('span',{directives:[{name:"date",rawName:"v-date",value:({ date: item.deadlineAt, localTZ: _vm.isLocalTimezone, mask: 'dd MMM yyyy, HH:mm' }),expression:"{ date: item.deadlineAt, localTZ: isLocalTimezone, mask: 'dd MMM yyyy, HH:mm' }"}]}):_vm._e()]}},{key:"item.course",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"accent--text"},[_vm._v(_vm._s(item.course.name))])]}},{key:"footer",fn:function(ref){
var props = ref.props;
var on = ref.on;
return [_c('TableFooter',{attrs:{"props":props,"filter":_vm.filter},on:{"update:filter":function($event){_vm.filter=$event}}})]}},{key:"no-data",fn:function(){return [_c('NoDataFound',{attrs:{"skin":"grey"}})]},proxy:true}])})],1)])])])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }