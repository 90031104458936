




































import { Component, Prop, Vue } from 'vue-property-decorator'

import Tag from '@/components/_uikit/Tag.vue'
import { ExerciseShortResource } from '@/store/types'
import AuthModule from '@/store/modules/auth'

@Component({
  components: {
    Tag,
  },
})
export default class ManagerExerciseCard extends Vue {
  @Prop({ required: true })
  private exercise!: ExerciseShortResource

  @Prop({ required: true })
  private index!: number

  @Prop({ default: false })
  private readonly!: boolean

  private get task () {
    return this.exercise && this.exercise.tasks.length > 0 ? this.exercise.tasks[0] : null
  }

  private get isEditable () {
    return !this.readonly
  }

  private get isLocalTimezone() {
    return AuthModule.isLocalTimezone
  }
}
