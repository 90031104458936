
























import { Component, Prop, Ref } from 'vue-property-decorator'

// components
import ExerciseModal from '@/components/modals/exercise/ExerciseModal.vue'
import Confirmation from '@/components/modals/Confirmation.vue'
import ManagerExerciseCard from '@/components/cards/ManagerExerciseCard.vue'
// mixins
import PermissionsMixin from '@/mixins/PermissionsMixin'
// store
import ManagerCoursesModule from '@/store/modules/manager/courses'
// types
import { ExerciseAccessLevel, ExerciseShortResource, ProgramMonthLargeResource } from '@/store/types'

@Component({
  components: {
    Confirmation,
    ExerciseModal,
    ManagerExerciseCard,
  },
})
export default class CoursesItemProgramMonthExercises extends PermissionsMixin {
  @Ref() confirm!: Confirmation

  @Prop({ required: true })
  private month!: ProgramMonthLargeResource

  private showExerciseModal = false
  private selectedExerciseUUID: string | null = null

  private get courseID () {
    return +this.$route.params.courseID
  }

  private get monthID () {
    return +this.$route.params.monthID
  }

  private get exercises () {
    return ManagerCoursesModule.exercises
  }

  private mounted () {
    this.fetchExercises()
    // Добавили задание из банка ДЗ
    this.$bus.$on('add-exercise-from-template', this.fetchExercises)
  }

  protected destroyed() {
    this.$bus.$off('add-exercise-from-template', this.fetchExercises as any)
  }

  private fetchExercises () {
    ManagerCoursesModule.fetchExercises({
      accessLevel: ExerciseAccessLevel.PUBLIC,
      courseID: this.courseID,
      monthID: this.monthID,
    })
      .catch(this.notifyError)
  }

  private handleSelectExercise (exercise: ExerciseShortResource) {
    this.selectedExerciseUUID = exercise.uuid
    this.showExerciseModal = true
  }

  private handleEditExercise (exercise: ExerciseShortResource) {
    this.$router.push({
      name: 'manager.control.courses.item.program.month.exercises.item.edit',
      params: {
        courseID: this.month.course.id.toString(),
        exerciseUUID: exercise.uuid,
        monthID: this.month.id.toString(),
      },
    })
  }

  private handleDeleteExercise (exercise: ExerciseShortResource) {
    ManagerCoursesModule.deleteExercise({
      courseID: this.month.course.id,
      exerciseUUID: exercise.uuid,
      monthID: this.month.id,
    })
      .then(() => {
        this.notifySuccess('Задание удалено')
        this.fetchExercises()
      })
      .catch(this.notifyError)
  }

  private confirmDeleteExercise (exercise: ExerciseShortResource) {
    this.confirm.open(
      'Удаление задания',
      `Вы уверены, что хотите удалить задание <span class="secondary--text font-weight-semibold">${exercise.title}</span>? После удаления, задание нельзя будет восстановить.`,
      {
        buttonConfirmText: 'Удалить',
        skin: 'secondary',
      },
    )
      .then(() => {
        this.handleDeleteExercise(exercise)
      })
      .catch(() => {return})
  }
}
