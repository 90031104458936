






















































import { Component, Prop, Watch } from 'vue-property-decorator'

// components
import TableFooter from '@/components/_uikit/TableFooter.vue'
import TextInput from '@/components/_uikit/controls/TextInput.vue'
// mixins
import NotifyMixin from '@/mixins/NotifyMixin'
// store
import AuthModule from '@/store/modules/auth'
import ManagerExercisesModule from '@/store/modules/manager/exercises'
import { GET_DEFAULT_TABLE_FILTER } from '@/store'
import { ExerciseCloneListResource, NameValueResource } from '@/store/types'
// utils
import { tableFooterOptions } from '@/utils/constants'

@Component({
  components: {
    TableFooter,
    TextInput,
  },
})
export default class CloneCoursesListModal extends NotifyMixin {
  @Prop({ required: true })
  private visible!: boolean

  @Prop({ required: true })
  private uuid!: string

  private get headers () {
    return [
      { filterable: false, sortable: false, text: 'Кто', value: 'author' },
      { filterable: false, sortable: false, text: 'Дата выдачи', value: 'spendingAt' },
      { filterable: false, sortable: false, text: 'Дата сдачи', value: 'deadlineAt' },
      {
        filter: (value: NameValueResource) => {
          if (!this.search) {
            return true
          }
          return value.name.toString().toLowerCase().indexOf(this.search) !== -1
        },
        sortable: false,
        text: 'Куда',
        value: 'course',
      },
    ]
  }

  private get isLocalTimezone() {
    return AuthModule.isLocalTimezone
  }

  private courses: ExerciseCloneListResource[] = []
  private search = ''

  private filter: any = {
    ...GET_DEFAULT_TABLE_FILTER(),
    itemsPerPage: 10,
  }
  private footerOptions = tableFooterOptions

  private scrollOptions = {
    scrollPanel: {
      scrollingX: false,
    },
  }

  private fetchCloneCoursesListExercise() {
    ManagerExercisesModule.fetchCloneCoursesListExercise(this.uuid)
      .then(response => this.courses = response)
      .catch(this.notifyError)
  }

  private handleClose () {
    this.$emit('update:visible', false)
    this.$emit('close', false)
  }

  private handleRowClick(course: ExerciseCloneListResource) {
    const path = this.$router.resolve({ name: 'manager.control.courses.item.program.month.exercises.item.edit', params: { courseID: course.course.value.toString(), exerciseUUID: course.uuid, monthID: course.monthId.toString() } })
    window.open(path.href, '_blank')
  }

  @Watch('visible')
  private watchVisible(value: boolean) {
    if (value) {
      this.fetchCloneCoursesListExercise()
    } else {
      this.search = ''
    }
  }
}

